export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'clientePotencial.update',
      {
        values: {
          idcliente_potencial: id,
          idempresa: formData.idempresa,
          idtcliente: formData.idtcliente,
          idpais: formData.idpais,
          idagente_comercial: formData.idagente_comercial,
          nombre: formData.nombre,
          razon_social: formData.razon_social,
          observaciones: formData.observaciones,
          idcanal_conocimiento: formData.idcanal_conocimiento,
          idtsector: formData.idtsector,
          idttarifa: formData.idttarifa,
          ididioma: formData.ididioma,
          cif: formData.cif,
          prospecto: formData.prospecto,
          idclidir_sistema: formData.cliente_direccion_servicio?.idcliente_direccion || null,
          idclidir_postal: formData.cliente_direccion_postal?.idcliente_direccion || null,
          idclidir_fiscal: formData.cliente_direccion_fiscal?.idcliente_direccion || null,
        },
      }
    )
  },
}

<template>
  <b10-base>
    <v-checkbox
      v-model="form.prospecto"
      label="Prospecto"
    />
    <v-text-field
      v-model="form.nombre"
      clearable
      flat
      label="Nombre"
      :rules="formRules.nombre"
      :append-icon="form.nombre ? null : $vuetify.icons.values.equals"
      @click:append="clickCopyRazonSocialANombre"
    />
    <v-text-field
      v-model="form.razon_social"
      clearable
      flat
      label="Razón social"
      :append-icon="form.razon_social ? null : $vuetify.icons.values.equals"
      @click:append="clickCopyNombreARazonSocial"
    />
    <v-text-field
      v-model="form.cif"
      clearable
      flat
      label="NIF"
    />
    <b10-subheader
      label="Datos comerciales"
    />
    <b10-autocomplete
      v-model="form.idtcliente"
      :items="tclientes"
      item-value="idtcliente"
      item-text="descripcion"
      label="Tipo de cliente"
      clearable
    />
    <b10-autocomplete
      v-model="form.idtsector"
      :items="tsectores"
      item-value="idtsector"
      item-text="descripcion"
      label="Actividad"
      clearable
    />
    <b10-autocomplete
      v-model="form.idcanal_conocimiento"
      :items="canalesConocimiento"
      item-value="idcanal_conocimiento"
      item-text="descripcion"
      label="Canal de conocimiento"
      clearable
    />
    <b10-autocomplete
      v-model="form.idagente_comercial"
      :items="agentesComerciales"
      item-value="idagente_comercial"
      item-text="nombre"
      label="Agente"
      clearable
    />
    <b10-autocomplete
      v-if="ttarifas.length > 1"
      v-model="form.idttarifa"
      :items="ttarifas"
      item-value="idttarifa"
      item-text="descripcion"
      label="Tipo de tarifa"
      clearable
      :rules="formRules.idttarifa"
    />
    <b10-subheader
      label="Direcciones"
      :icon="$vuetify.icons.values.direcciones"
    />
    <b10-autocomplete
      v-model="form.cliente_direccion_postal"
      :items="direcciones"
      item-text="descripcion"
      label="Postal"
      return-object
      clearable
    />
    <b10-autocomplete-alert
      v-if="form.cliente_direccion_postal"
    >
      {{ form.cliente_direccion_postal.descripcion }}
    </b10-autocomplete-alert>
    <b10-autocomplete
      v-model="form.cliente_direccion_fiscal"
      :items="direcciones"
      item-text="descripcion"
      label="Fiscal"
      return-object
      clearable
    />
    <b10-autocomplete-alert
      v-if="form.cliente_direccion_fiscal"
    >
      {{ form.cliente_direccion_fiscal.descripcion }}
    </b10-autocomplete-alert>
    <b10-autocomplete
      v-model="form.cliente_direccion_servicio"
      :items="direcciones"
      item-text="descripcion"
      label="De servicio"
      return-object
      clearable
    />
    <b10-autocomplete-alert
      v-if="form.cliente_direccion_servicio"
    >
      {{ form.cliente_direccion_servicio.descripcion }}
    </b10-autocomplete-alert>
    <b10-subheader
      label="Otros datos"
      :icon="$vuetify.icons.values.idioma"
    />
    <b10-autocomplete
      v-model="form.ididioma"
      :items="idiomas"
      item-value="ididioma"
      item-text="descripcion"
      label="Idioma"
      clearable
      :rules="formRules.ididioma"
    />
    <b10-autocomplete
      v-model="form.idpais"
      :items="paises"
      item-value="idpais"
      item-text="descripcion"
      label="Nacionalidad"
      clearable
    />
    <b10-autocomplete
      v-if="empresas.length > 1"
      v-model="form.idempresa"
      :items="empresas"
      item-value="idempresa"
      item-text="nombre"
      label="Empresa"
      clearable
      :rules="formRules.idempresa"
    />
    <b10-subheader
      label="Comentarios"
      :icon="$vuetify.icons.values.edit"
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './ClientePotencialEditFormData'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        prospecto: false,
        nombre: null,
        razon_social: null,
        cif: null,
        idtcliente: null,
        idtsector: null,
        idcanal_conocimiento: null,
        idagente_comercial: null,
        observaciones: null,
        ididioma: null,
        idpais: null,
        idempresa: null,
        idttarifa: null,
        cliente_direccion_postal: null,
        cliente_direccion_fiscal: null,
        cliente_direccion_servicio: null,
      },
      formRules: {
        nombre: [
          v => !!v || 'Campo requerido'
        ],
        ididioma: [
          v => !!v || 'Campo requerido'
        ],
        idempresa: [
          v => !!v || 'Campo requerido'
        ],
        idttarifa: [
          v => !!v || 'Campo requerido'
        ],
      },
      idiomas: [],
      paises: [],
      tclientes: [],
      tsectores: [],
      canalesConocimiento: [],
      agentesComerciales: [],
      empresas: [],
      ttarifas: [],
      direcciones: [],
    }
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.idiomas = resp.data.selectIdioma.result.dataset
    this.paises = resp.data.selectPais.result.dataset
    this.tclientes = resp.data.selectTcliente.result.dataset
    this.tsectores = resp.data.selectTsector.result.dataset
    this.canalesConocimiento = resp.data.selectCanalConocimiento.result.dataset
    this.agentesComerciales = resp.data.selectAgenteComercial.result.dataset
    this.empresas = resp.data.selectEmpresa.result.dataset
    this.ttarifas = resp.data.selectTtarifa.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const metadata = await Data.selectFormData(this, -1)
      this.addFormRulesAPI(metadata[1].columns)
      const [formData] = await Data.selectFormData(this, id)
      const resp = await Data.selectLookupsFormData(this, id)
      this.direcciones = resp.data.selectClienteDireccion.result.dataset
      formData.cliente_direccion_postal = _.find(this.direcciones, { 'idcliente_direccion': formData.idcliente_direccion_postal})
      formData.cliente_direccion_fiscal = _.find(this.direcciones, { 'idcliente_direccion': formData.idcliente_direccion_fiscal})
      formData.cliente_direccion_servicio = _.find(this.direcciones, { 'idcliente_direccion': formData.idcliente_direccion_servicio})
      this.form = formData
    },
    clickCopyRazonSocialANombre () {
      this.$set(this.form, 'nombre', this.form.razon_social)
    },
    clickCopyNombreARazonSocial () {
      this.$set(this.form, 'razon_social', this.form.nombre)
    },
  }
}
</script>
import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    apiCalls.push({ name: 'selectIdioma', method: 'idioma.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectPais', method: 'pais.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTcliente', method: 'tcliente.select', params: { filter: apiFilterEstadoActivo } })
    // TSECTOR no tiene campo estado
    apiCalls.push({ name: 'selectTsector', method: 'tsector.select' })
    apiCalls.push({ name: 'selectCanalConocimiento', method: 'canalConocimiento.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectAgenteComercial', method: 'agenteComercial.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectEmpresa', method: 'empresa.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTtarifa', method: 'ttarifa.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idcliente_potencial', id)
    const resp = await Vue.$api.call('clientePotencial.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
  async selectLookupsFormData (Vue, idclientePotencial) {
    let apiCalls = []
    const apiFilterDirecciones = new APIFilter()
    apiFilterDirecciones
      .addGT('estado', 0)
      .addExact('idcliente_potencial', idclientePotencial)
    apiCalls.push({ name: 'selectClienteDireccion', method: 'clienteDireccion.select', params: { filter: apiFilterDirecciones } })
    return await Vue.$api.batchCall(apiCalls)
  }
}
